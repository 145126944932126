<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4.25rem;">Modi</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img src="@/assets/illustrations/modibiker.svg" alt="biker" style="width: 100%; height: 100%; margin-top: 2rem; margin-bottom: 1.25rem;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="flex-grow-1">
      <b-col cols="1"/>
        <b-col class="justify-content-center align-self-center">
          <b-button variant="primary" style= "margin-top: 4rem; width: 100%;" @click="$router.push('/')" text="Fahrrad leihen">Fahrrad leihen</b-button>
          <p class="txtButton" @click="$router.push('/')">Fahrrad verleihen</p>
        </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>


export default {
  name: 'Modi',
  components: {
  }
}
</script>

<style RoundedButton>

</style>